exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-animals-js": () => import("./../../../src/pages/animals.js" /* webpackChunkName: "component---src-pages-animals-js" */),
  "component---src-pages-crystal-js": () => import("./../../../src/pages/crystal.js" /* webpackChunkName: "component---src-pages-crystal-js" */),
  "component---src-pages-energy-js": () => import("./../../../src/pages/energy.js" /* webpackChunkName: "component---src-pages-energy-js" */),
  "component---src-pages-guidance-js": () => import("./../../../src/pages/guidance.js" /* webpackChunkName: "component---src-pages-guidance-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-light-js": () => import("./../../../src/pages/light.js" /* webpackChunkName: "component---src-pages-light-js" */),
  "component---src-pages-meditation-js": () => import("./../../../src/pages/meditation.js" /* webpackChunkName: "component---src-pages-meditation-js" */),
  "component---src-pages-news-js": () => import("./../../../src/pages/news.js" /* webpackChunkName: "component---src-pages-news-js" */),
  "component---src-templates-blog-js": () => import("./../../../src/templates/blog.js" /* webpackChunkName: "component---src-templates-blog-js" */),
  "component---src-templates-blog-list-js": () => import("./../../../src/templates/blog-list.js" /* webpackChunkName: "component---src-templates-blog-list-js" */)
}

